import * as React from 'react';
import styled, { css } from 'styled-components';
import { Trans } from '@lingui/react';
import Carousel from '@organisms/Carousel';
import { Box, Container, Flex } from '@components/layout/Grid';
import SectionHeader from '@components/SectionHeader';

/** Types */

/** Components */
// export const Devider = styled.div`
//   height: 1px;
//   display: block;
//   background-color: rgba(0, 0, 0, 0.87);
//   border: 0;
// `;

const GraySectionWrapper = styled.div`
  background-color: #f7f7f7;
`;

const HeroSectionWrapper = styled.div`
  display: block;
  background-color: ${props => props.theme.colors.primary['blue-4']};
  text-align: center;
`;

const HeroImageSectionWrapper = styled.div`
  /* background-color: white; */
  background-image: url('/static/register_social_hero.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  text-align: left;
  border-radius: 3px;
`;

export const HighlitedSection = styled.div`
  background-color: rgba(167, 141, 81, 0.9);
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
`;

export const HighlitedSectionDynamic = styled.div<{ summerCampaign?: boolean }>`
  background-color: ${p =>
    p.summerCampaign ? 'rgba(136, 191, 189, 0.7)' : 'rgba(17, 158, 221, 0.75)'};
  padding-top: 6.17%;
  padding-bottom: 6.17%;
  display: flex;
`;

export const HighlitedSectionParent = styled.div`
  min-height: 560px;
  max-height: 600px;
  position: relative;
  @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
    min-height: 350px;
    max-height: 400px;
  }
`;

export const HighlitedSectionParentDynamic = styled.div`
  position: relative;
`;

const SubscriptionTable = styled.div`
  background-image: url('/static/subscription_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
`;

export const SubscriptionTags = styled.div`
  border-radius: 3px;
  background-color: rgba(102, 123, 145, 0.2);
`;

export const SubscriptionTag = styled.div`
  position: relative;
  color: ${props => props.theme.colors.neutrals.black};
  font-size: 13px;
  font-weight: 300;
  line-height: 23px;
`;

export const SubscriptionsWrapper = styled(Flex)<{ highlightColor?: string }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 10px;
  row-gap: 20px;
`;

export const SubscriptionBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.colors.neutrals.black};
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  padding: 40px 25px;
  background-color: white;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.3s ease-in;
  overflow: hidden;

  @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
    padding: 25px 15px;
  }

  &:hover {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const SubscriptionBoxTitle = styled.h3`
  color: ${props => props.theme.colors.neutrals.black};
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1.18px;
  line-height: 19px;
  text-transform: uppercase;
`;

export const SubscriptionBoxPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubscriptionBoxOldPrice = styled.span`
  color: ${({ theme }) => theme.colors.neutrals.black};
  font-size: 24px;
  font-weight: 300;
  line-height: 1.4;
  text-decoration: line-through;
`;

export const SubscriptionBoxPrice = styled.span`
  color: #243240;
  font-size: 32px;
  font-weight: 600;
  line-height: 51px;
  letter-spacing: -1.5px;
  margin-right: 14px;
`;

export const SubscriptionBoxBilled = styled.p`
  max-width: 300px;
  font-size: 13px;
  font-weight: 600;
  line-height: 23px;
  text-wrap: balance;
  min-height: 46px;
  margin: 18px auto 20px;
`;

export const SubscriptionBoxBilledSave = styled.strong`
  color: ${props => props.theme.colors.primary['pink-1']};
  display: block;
`;

export const Label = styled.label`
  color: #ffffff;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  padding: 4px 11.5px;
  border-radius: 2px;
  display: inline-block;
  background-color: ${props => (props.color ? props.color : '#404040')};
  margin-bottom: 5px;
`;

export const BestPriceLabel = styled.div`
  position: absolute;
  top: 30px;
  right: -76px;
  width: 240px;
  font-size: 11px;
  color: ${props => props.theme.colors.neutrals['white']};
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: ${({ theme }) => theme.colors.primary['blue-2']};
  padding: 8px;
  transform: rotate(45deg);
`;

export const MainTitle = styled.h1`
  color: ${props => props.theme.colors.neutrals.black};
  font-size: 48px;
  font-weight: bold;
  line-height: 58px;
  text-align: center;
  margin: 0;
  margin-bottom: 20px;

  @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
    font-size: 32px;
    line-height: 42px;
  }
`;

export const MainDescription = styled.p`
  color: ${props => props.theme.colors.neutrals.black};
  font-size: 13px;
  line-height: 23px;
  margin: 0;
  margin-top: 20px;
`;

export const HeroImageTitle = styled.h1`
  color: #ffffff;
  font-size: 28px;
  font-weight: 300;
  line-height: 34px;
  text-align: left;
`;

export const ColumnParagraph = styled.p`
  margin: 0;
  padding: 0;
  color: ${props => props.theme.colors.neutrals.black};
  font-size: 13px;
  line-height: 23px;
`;

export const ColumnImage = styled.img<{ maxWidth?: number | string }>`
  margin: 0;
  padding: 0;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : `100%`)};
  width: 100%;
  pointer-events: none;
`;

export const SingleColumnTitle = styled.h2`
  color: ${props => props.theme.colors.neutrals.black};
  font-size: 34px;
  font-weight: 300;
  line-height: 41px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 15px;
`;

export const SingleColumnParagraphInner = styled.p<{ expanded?: boolean }>`
  ${props =>
    !props.expanded
      ? css`
          &:before {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            background: rgb(247, 247, 247);
            background: linear-gradient(
              0deg,
              rgba(247, 247, 247, 1) 0%,
              rgba(247, 247, 247, 0) 50%
            );
          }
        `
      : null};
  overflow: hidden;
  margin: 0;
  padding: 0;
  max-height: ${props => (props.expanded ? null : '240px')};
  font-size: 14px;
  line-height: 26px;
  color: ${props => props.theme.colors.neutrals.black};
  transition: all ease-in 0.3s;
`;
export const SingleColumnParagraph = styled.div`
  color: ${props => props.theme.colors.neutrals.black};
  position: relative;
`;

export const SubscriptionTitle = styled.h2`
  color: ${props => props.theme.colors.neutrals.black};
  font-size: 34px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
`;

export const SubscriptionSubTitle = styled.p`
  color: ${props => props.theme.colors.neutrals.black};
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  line-height: 21px;
  text-align: center;
`;

export const Section = template => {
  switch (template.type) {
    case 'hero':
      return (
        <HeroSectionWrapper>
          <Container
            flexDirection={'column'}
            mt="60px"
            mb="60px"
            px={[25, 25, 10]}
          >
            <Flex
              flexWrap={'nowrap'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Box width={[1, 1, 1 / 2]}>{template.children}</Box>
            </Flex>
          </Container>
        </HeroSectionWrapper>
      );
    case 'hero-image':
      return (
        <Container fullWidth as={GraySectionWrapper} pt={30} px={[25, 25, 10]}>
          <Container
            as={HeroImageSectionWrapper}
            flexDirection={'column'}
            px={[0, 30, 60]}
          >
            <Flex as={HighlitedSectionParent} alignItems={'center'}>
              <Box width={[1, 1, 2 / 5]} as={HighlitedSection} px={40} py={20}>
                {template.children}
              </Box>
            </Flex>
          </Container>
        </Container>
      );
    case 'subscription':
      return (
        <Container fullWidth as={SubscriptionTable} py={60}>
          <Container px={[25, 25, 10]} flexDirection={'column'}>
            <Flex alignItems={'center'} justifyContent={'center'}>
              <Box flex="1">{template.children}</Box>
            </Flex>
          </Container>
        </Container>
      );
    case '2-columns':
      return (
        <Container
          flexDirection={'column'}
          mt="53px"
          mb="53px"
          px={[25, 25, 106]}
        >
          <Flex>
            <Box flex="1">{template.children}</Box>
          </Flex>
        </Container>
      );
    case '1-column':
      return (
        <Container fullWidth as={GraySectionWrapper}>
          <Container flexDirection={'column'} my="60px" px={[25, 25, 10]}>
            <Flex
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Box mx="60px" width={[1, 1, 1 / 2]}>
                {template.children}
              </Box>
            </Flex>
          </Container>
        </Container>
      );
    case 'testimonials':
      return (
        <Container fullWidth as={GraySectionWrapper}>
          <Container flexDirection={'column'} my="60px" px={[25, 25, 10]}>
            <Box mb="50px">
              <SectionHeader
                title={
                  <Trans
                    id="regsocial.testimonial_title"
                    message={'What our members think'}
                  />
                }
              />
            </Box>
            <Carousel itemPerView={3}>{template.children}</Carousel>
          </Container>
        </Container>
      );
    default:
      break;
  }
};
